import React, { useContext } from 'react'
import "../styles/style.scss"
import eye from "../assets/EyeofPingus2.svg"
import { AuthContext } from '../context/AuthContext'
import { signOut } from 'firebase/auth'
import { auth } from '../firebase'
import { useNavigate } from 'react-router-dom'

export const Home = () => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  console.log(currentUser)

  if (false) {
    return (
      <div className='homeContainer'>
        {/* <Usernav /> */}
        <span> Hi, {currentUser.displayName}!</span>

        <div className="homeContent">
          {/* <p>
            In a world where beer pong is more than just a game, a fellowship of players compete for special rings that grant them powers. Ring players must play one games a week, three games max, including matches against those who lost their rings. The ultimate goal is to win a 1v1 tournament, where the victor takes all the rings and becomes the Lord of the Rings.
          </p> */}

          <img src={eye} alt=""></img>
          <button onClick={() => signOut(auth)}> logout</button>
        </div>
      </div>
    )
  } else {
    return (
      <div className='homeContainer'>
        {/* <Header /> */}

        <div className="homeContent">
          {currentUser ? <span> Hi, {currentUser.displayName}</span> : <span> Got a tournament? </span>}
          <img src={eye} alt=""></img>
          <button onClick={() => navigate("/createleague")}> {currentUser ? "Create Tournament!" : "Get Started!"}</button>
        </div>
      </div>
    )

  }
}
