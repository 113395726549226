import React, { useContext, useEffect, useState } from 'react'
// import Game from '../componenets/Game'
import { doc, arrayUnion, collection, query, where, getDocs, updateDoc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";
import "../styles/style.scss"
import "../styles/leagues.scss"
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { AuthContext } from '../context/AuthContext'
import { useParams } from 'react-router-dom';
import FriendRequest from '../componenets/FriendRequest';
import { signOut } from 'firebase/auth'
import { auth } from '../firebase'
import { useNavigate } from "react-router-dom";
import eye from "../assets/EyeofPingus2.svg"

const Profile = () => {
  const [err, setErr] = useState(false)
  const { currentUser } = useContext(AuthContext);
  const [friendRequest, setFriendRequest] = useState(null)
  const [sameUser, setSameUser] = useState(false)
  const params = useParams();
  const userUID = params.id.substring(1)
  const [user, setUser] = useState(null)
  let [tournamentList, setTournamentList] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    getUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  useEffect(() => {
    if (userUID === currentUser.uid) {
      setSameUser(true)
      console.log(sameUser)
    }
  }, [userUID, currentUser, sameUser])

  useEffect(() => {
    // Only execute the query if the currentUser is defined.
    if (currentUser) {
      // Query the collection with the where clause


      if (currentUser && currentUser.uid) {
        const queryRef = query(
          collection(db, "tournaments"),
          where("admin", "==", currentUser.uid)
        );

        // Subscribe to real-time updates
        const unsubscribe = onSnapshot(queryRef, (snapshot) => {
          const tournaments = [];
          snapshot.forEach((doc) => {
            tournaments.push(doc.data());
          });
          setTournamentList(tournaments);
        });


        // Clean up the subscription on unmount
        return () => {
          unsubscribe();
        };
      }
    }
  }, [currentUser]); // Added dependency to re-run effect if currentUser changes

  const handleSelect = async (tournamentID) => {
    // check whether the group (chat in firestore) exists if not create one
    navigate(`/leagues/:${tournamentID}`)
  }


  const getUser = async () => {
    const q = query(collection(db, "users"), where("uid", "==", userUID))
    try {
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        setUser(doc.data())
        if (userUID === currentUser.uid) {
          setFriendRequest(doc.data().friendRequest)
        }
      })

    } catch (err) {
      console.log(err)
      setErr(true)
    }
  }

  const handleAddFriend = async (e) => {
    e.preventDefault()
    // create a userFriendlist
    await updateDoc(doc(db, "users", userUID), {
      friendRequest: arrayUnion(currentUser.uid)
    });
  }

  const handleSignOut = async () => {
    await signOut(auth);
    navigate('/');  // navigate to home page
  };

  return (
    <div className='homeContainer' >
      {err && <span> Error </span>}
      <div className="profileContainer">
        <div className="profileBox">
          {user && <div className="circle"> {user.displayName}</div>}
          {user ? <span> {user.displayName} </span> : <span> loading.... </span>}
          <div className="winHistory">
            <EmojiEventsIcon className='trophy' />
            <div className="stats">
              {/* {user ? <span className='number'>  {user.gamesWon} </span> : <span className='number'>  4 </span>} */}
              {user ? <span className='number'>  Feature Coming Soon </span> : <span className='number'>  4 </span>}
              {/* <span className="text">  Games Won </span> */}
            </div>
          </div>
        </div>
        {!sameUser &&
          <div>
            <button onClick={handleAddFriend}> Add Friend </button>
          </div>
        }

        {friendRequest &&
          <div className="gameBox">
            <span className="spanTitle"> Friend Request</span>
            <div className='friendReqContainer'>
              {
                friendRequest.map((friend) => (
                  <FriendRequest friend={friend} key={friend} currentUser={currentUser.uid} />
                ))
              }
            </div>
          </div>

        }

        {/* <div className="gameBox">
          <span> My Games</span>
          <Game />
          <Game />
          <Game />
        </div> */}
        <div className="profileTournaments">
          <span className="spanTitle"> My Tournaments </span>
          {tournamentList && tournamentList.map((tournament, index) => (
            <div className='leagueContainer' key={index} onClick={() => handleSelect(tournament.UID)} >
              <div className="leagueLeft"> </div>
              <span> {tournament.name} </span>
              <img className="leagueGraphic" src={eye} alt=""></img>
              <div className="leagueRight"> </div>
            </div >
          ))
          }
          <button className='createLeagues' onClick={() => { navigate("/createleague") }}>  Create New Tournament </button>
        </div>

        <span className="spanTitle"> Settings & Account </span>
        <button onClick={handleSignOut}> logout</button>
      </div>
    </div>
  )
}

export default Profile