import React, { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom';
import { collection, query, where, getDocs } from "firebase/firestore";
import { incrementAndSync, updateWholeBracket } from '../actions';
import { db } from "../firebase";
import Bracket from './Bracket';
import { useDispatch, useSelector } from 'react-redux';
import { AuthContext } from '../context/AuthContext'
import "../styles/leagues.scss"
import "../styles/bracket.scss"
import BracketForm from './BracketForm';

const TournamentDetails = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const tournamentID = params.id.substring(1)
  let [err, setErr] = useState(false)
  const [tournament, setTournament] = useState(null)
  const { currentUser } = useContext(AuthContext);
  const [status, setStatus] = useState(null);
  const initialState = useSelector(state => state.bracket);
  const [inputValue, setInputValue] = useState("");
  // let d = useSelector(state => state.bracket);

  useEffect(() => {
    getTournament()
    console.log("tournament detail page")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  const handleAdd = () => {
    console.log(initialState)

    if (inputValue === null || inputValue.trim() === '') {
      console.error("The input value cannot be null or empty.");
      // Optionally, you can set an error state to provide feedback to the user.
      setErrorTemporarily("please enter a valid name")
    } else {
      dispatch(incrementAndSync(inputValue, tournamentID));
      // dispatch(increment(inputValue));
      setStatusTemporarily("Team added!");
      setInputValue()
    }

  };

  const setStatusTemporarily = (message) => {
    setStatus(message);
    setTimeout(() => setStatus(null), 3000);
  };


  const setErrorTemporarily = (message) => {
    setErr(message);
    setTimeout(() => setErr(null), 3000);
  };

  const getTournament = async () => {
    const q = query(collection(db, "tournaments"), where("UID", "==", tournamentID))
    try {
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        setTournament(doc.data())
        // update initial state
        dispatch(updateWholeBracket(doc.data()));
      })

    } catch (err) {
      console.log(err)
      setErr(true)
    }
  }

  return (
    <div className="editLeague">
      {currentUser && initialState && currentUser.uid === initialState.admin ? <span className='adminSpan'> Admin Edit Panel </span> :
        <div className="joinTournament">
          <span> Enter your team name to enter the tournament! </span>
          <div className='inputContainer'>
            <input
              type="text"
              placeholder={"Team " + (parseInt(initialState.count) + 1)}
              defaultValue={"Team " + (parseInt(initialState.count) + 1)}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />

            <button className="tournamentButton" onClick={handleAdd}> join tournament </button>
          </div>
          {err && <p>{err}</p>}

        </div>}
      {status && <p>{status}</p>}
      <div className='leagueEditContainer'>
        {
          currentUser && tournament && currentUser.uid === tournament.admin
            ?
            <>
              <BracketForm />
            </>
            :
            null
        }
        <div className='bracketDisplay'>
          {tournament && <Bracket tournament={tournament} />}
        </div>
      </div>
    </div>
  )
}

export default TournamentDetails