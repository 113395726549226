import React, { useContext, useState } from 'react'
import { collection, addDoc, updateDoc, doc } from "firebase/firestore";
import { db } from "../firebase";
import { increment, decrement, updateName, updateMode, updateType, updateDescription, updateTeamName } from '../actions';
import { AuthContext } from '../context/AuthContext'
import { useSelector, useDispatch } from 'react-redux';
import "../styles/bracket.scss"
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useNavigate } from "react-router-dom";

const BracketForm = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const dispatch = useDispatch();
  const count = useSelector(state => state.bracket.count);
  let initialState = useSelector(state => state.bracket);
  const [err, setErr] = useState(null);
  const [status, setStatus] = useState(null);
  const [open, setOpen] = useState(false);

  const handleAdd = () => {
    dispatch(increment());
  }

  const handleSubtract = () => {
    dispatch(decrement());
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const setStatusTemporarily = (message) => {
    setStatus(message);
    setTimeout(() => setStatus(null), 3000);
  };

  const handleClose = () => setOpen(false);

  const HandleSubmit = async (e) => {
    console.log(initialState)
    const tournamentCollection = collection(db, "tournaments");

    if (initialState.name === "Untitled Bracket") {
      setErr("Please name your tournament")
    } else {
      if (currentUser) {
        try {
          const docRef = await addDoc(tournamentCollection, {
            ...initialState,
            admin: currentUser.uid,
          });
          console.log("Document written with ID: ", docRef.id);

          // add UID to the tournament
          await updateDoc(docRef, {
            UID: docRef.id,
          });

          navigate(`/leagues/:${docRef.id}`)
        } catch (error) {
          setErr(error);
          console.error("Error adding document: ", error);
        }
      } else {
        console.log("not signed in")
        setErr("You must be signed in to create a tournament")
        handleOpen()
      }
    }
  }

  const HandleEdit = async () => {
    if (!initialState.UID) {
      console.error("No UID provided. Cannot edit document.");
      return;
    }

    const tournamentDocRef = doc(db, "tournaments", initialState.UID);

    if (initialState.name === "Untitled Bracket") {
      setErr("Please name your tournament");
      return;
    }

    if (currentUser) {
      try {
        // Update the document by its UID
        await updateDoc(tournamentDocRef, {
          ...initialState,
        });

        console.log("Document updated with ID: ", initialState.UID);
        setStatusTemporarily("Tournament updated successfully")
        // navigate(`/leagues/:${initialState.UID}`);


      } catch (error) {
        setErr(error);
        console.error("Error updating document: ", error);
      }
    } else {
      console.log("not signed in");
      setErr("You must be signed in to edit a tournament");
      handleOpen();
    }
  };


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    // p: 4,
    visable: 'hidden'
  };

  return (

    <div className='bracketFormContainer'>
      <form>
        <label>
          Name:
          <input onChange={(e) => dispatch(updateName(e.target.value))} type="text" name="name" placeholder='untitled bracket' value={initialState.name !== "Untitled Bracket" ? initialState.name : ""} />

        </label>
        <label htmlFor="bracketType">Bracket Type:</label>
        <select onChange={(e) => dispatch(updateType(e.target.value))} id="bracketType" name="gameMode">
          <option value="0">Single Elimination</option>
          {/* <option value="1">Double Elimination</option> */}
        </select>
        <label htmlFor="gameMode">Game Mode:</label>
        <select onChange={(e) => dispatch(updateMode(e.target.value))} id="gameMode" name="gameMode">
          <option value="0">2 v 2</option>
          {/* <option value="1">1 v 1</option> */}
        </select>


        <label>
          Descriptions:
          <textarea
            onChange={(e) => dispatch(updateDescription(e.target.value))}
            type="text"
            id="description"
            name="description"
            rows="4"
            cols="25"
            // placeholder='ping ping ping'
            value={initialState.description}
          />
        </label>

        <div className="participantPanel">
          <div className="participantHeader">
            <span> Participants </span>
            <div>
              <button type="button" onClick={handleAdd}>+</button>
              <button type="button" onClick={handleSubtract}>-</button>
            </div>
          </div>
          <div className="participantList">
            {
              Object.keys(initialState.teams)
                .filter((team) => team !== "BYE" && team !== "TBD")
                .sort((a, b) => initialState.teams[a].seed - initialState.teams[b].seed)
                .map((team, teamIndex) => {
                  return (
                    <div className='teamLabel' key={teamIndex}>
                      <span> {initialState.teams[team].seed}</span>
                      <input onChange={(e) => dispatch(updateTeamName(e.target.value, team))} type="text" value={initialState.teams[team].teamName} />
                    </div>
                  )
                })
            }

          </div>
        </div>


        <div>Current Team Count: {count}</div>

        {initialState.admin === "uid" ?
          < button type="button" className="tournamentButton" value="Submit" onClick={HandleSubmit} > create tournament </button>
          :
          < button type="button" className="tournamentButton" value="Submit" onClick={HandleEdit} > update tournament </button>
        }

        {err && <span className="error"> {err} </span>}
        {status && <div className="status"> {status} </div>}
      </form>
      <div>
        {/* <Button onClick={handleOpen}>Open modal</Button> */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2"> </Typography>
            <div className="createModal">
              <span> Create an Account </span>

              <p> You must be signed in to create a tournament </p>
              <div>
                <button className='signup' onClick={() => { navigate("/signup") }}>sign up </button>
                <button className='login' onClick={() => { navigate("/login") }}> log in </button>
              </div>
            </div>

          </Box>
        </Modal>
      </div>
    </div >

  )

}

export default BracketForm